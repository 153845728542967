const LoadingComponent = (props) => {
    
    if(props.isShow === true)
    {
        return(
            <div>
                    <div className="loading">Loading&#8230;</div> 
            </div>
        )
    }
}
export default LoadingComponent;